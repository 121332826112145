/* General */

:root {
  --color-indigo: #473bcc;
  --color-gold: #ffd600;
  --color-white: #ffffff;
  --color-gray-1: #e8e8e8;
  --color-gray-2: #d9d9d9;
  --color-gray-3: #bcbcbc;
  --color-gray-4: #9d9d9d;
  --color-gray-5: #3b3b3b;
  --color-gray-6: #212427;
}

* {
  box-sizing: border-box;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--color-white);
  color: var(--color-gray-6);
  font-family: Garamond, serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-header {
  background-color: var(--color-indigo);
  color: var(--color-gold);
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 1.5em;
  font-weight: bold;
  width: 100vw;
}

.site-footer {
  text-align: center;
  padding: 0.25rem;
  font-size: 1em;
}

/* Calculator */

.calculator {
  width: 100%;
  max-width: 26.5625rem;
  padding: 0.1rem;
  margin-bottom: 2rem;
  flex: 1;
}

.calculator-display {
  background-color: var(--color-gray-1);
  border: 1px solid var(--color-gray-5);
  border-radius: 5px;
  width: 100%;
  height: 8.8rem;
  margin-bottom: 0.4rem;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  overflow: hidden;
}

.calculator-prev-expr {
  font-size: 1.6em;
  text-wrap: nowrap;
}

.calculator-cur-expr {
  font-size: 3.6em;
  text-wrap: nowrap;
}

.calculator-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.calculator-row {
  display: flex;
  gap: 0.2rem;
}

/* Calculator Buttons */

.calculator-btn {
  width: 100%;
  height: 4.4rem;
  border: 1px solid var(--color-gray-1);
  border-radius: 5px;
  font-size: 1.2em;
  flex: 1;
  color: var(--color-gray-6); /* fix for iOS */
}

.calculator-btn:active {
  box-shadow: 1px 1px 5px 0px var(--color-gray-5);
}

.calculator-btn:focus {
  border: 1px solid var(--color-gray-6);
}

.calculator-btn:hover {
  border: 1px solid var(--color-gray-5);
}

.calculator-btn-number {
  background-color: var(--color-gray-2);
}

.calculator-btn-numeral {
  background-color: var(--color-gray-3);
}

.calculator-btn-control {
  background-color: var(--color-gray-4);
}

.calculator-btn-backspace-icon {
  width: 2rem;
  height: 2rem;
  margin: auto;
}

.calculator-btn-decimal {
  font-weight: bold;
}

.calculator-btn-convert {
  flex: 3.128;
}

.calculator-btn-equals {
  background-color: var(--color-indigo);
  color: var(--color-white);
  font-weight: bold;
}

/* Tooltips */

:has(.tooltip) {
  position: relative;
}

.tooltip {
  width: 10rem;
  background-color: var(--color-gray-5);
  color: var(--color-white);
  border-radius: 4px;
  padding: 0.6rem;
  font-size: 0.8em;
  font-weight: normal;
  position: absolute;
  z-index: 1;
  top: -100%;
  left: calc(50% - 5rem);
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: calc(50% - 9px);
  border-width: 9px;
  border-style: solid;
  border-color: var(--color-gray-5) transparent transparent transparent;
}

@media only screen and (min-width: 30em) {
  .site-header {
    font-size: 1.9em;
  }

  .site-footer {
    font-size: 1.2em;
  }

  .calculator-btn {
    font-size: 1.4em;
  }
}
