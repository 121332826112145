:root {
  --color-indigo: #473bcc;
  --color-gold: #ffd600;
  --color-white: #fff;
  --color-gray-1: #e8e8e8;
  --color-gray-2: #d9d9d9;
  --color-gray-3: #bcbcbc;
  --color-gray-4: #9d9d9d;
  --color-gray-5: #3b3b3b;
  --color-gray-6: #212427;
}

* {
  box-sizing: border-box;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

body {
  background-color: var(--color-white);
  color: var(--color-gray-6);
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: Garamond, serif;
  display: flex;
}

.site-header {
  background-color: var(--color-indigo);
  color: var(--color-gold);
  text-align: center;
  width: 100vw;
  margin-bottom: 2rem;
  padding: 1rem;
  font-size: 1.5em;
  font-weight: bold;
}

.site-footer {
  text-align: center;
  padding: .25rem;
  font-size: 1em;
}

.calculator {
  flex: 1;
  width: 100%;
  max-width: 26.5625rem;
  margin-bottom: 2rem;
  padding: .1rem;
}

.calculator-display {
  background-color: var(--color-gray-1);
  border: 1px solid var(--color-gray-5);
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 8.8rem;
  margin-bottom: .4rem;
  padding: .7rem;
  display: flex;
  overflow: hidden;
}

.calculator-prev-expr {
  text-wrap: nowrap;
  font-size: 1.6em;
}

.calculator-cur-expr {
  text-wrap: nowrap;
  font-size: 3.6em;
}

.calculator-table {
  flex-direction: column;
  gap: .2rem;
  width: 100%;
  display: flex;
}

.calculator-row {
  gap: .2rem;
  display: flex;
}

.calculator-btn {
  border: 1px solid var(--color-gray-1);
  color: var(--color-gray-6);
  border-radius: 5px;
  flex: 1;
  width: 100%;
  height: 4.4rem;
  font-size: 1.2em;
}

.calculator-btn:active {
  box-shadow: 1px 1px 5px 0px var(--color-gray-5);
}

.calculator-btn:focus {
  border: 1px solid var(--color-gray-6);
}

.calculator-btn:hover {
  border: 1px solid var(--color-gray-5);
}

.calculator-btn-number {
  background-color: var(--color-gray-2);
}

.calculator-btn-numeral {
  background-color: var(--color-gray-3);
}

.calculator-btn-control {
  background-color: var(--color-gray-4);
}

.calculator-btn-backspace-icon {
  width: 2rem;
  height: 2rem;
  margin: auto;
}

.calculator-btn-decimal {
  font-weight: bold;
}

.calculator-btn-convert {
  flex: 3.128;
}

.calculator-btn-equals {
  background-color: var(--color-indigo);
  color: var(--color-white);
  font-weight: bold;
}

:has(.tooltip) {
  position: relative;
}

.tooltip {
  background-color: var(--color-gray-5);
  color: var(--color-white);
  z-index: 1;
  border-radius: 4px;
  width: 10rem;
  padding: .6rem;
  font-size: .8em;
  font-weight: normal;
  position: absolute;
  top: -100%;
  left: calc(50% - 5rem);
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: 9px;
  border-color: var(--color-gray-5) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: calc(50% - 9px);
}

@media only screen and (width >= 30em) {
  .site-header {
    font-size: 1.9em;
  }

  .site-footer {
    font-size: 1.2em;
  }

  .calculator-btn {
    font-size: 1.4em;
  }
}
/*# sourceMappingURL=index.8eeddcd0.css.map */
